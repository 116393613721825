import React, { useState } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Badge,
} from "@mui/material";
import useResponsive from "./../../hooks/useResponsive";
import data from "../../utilities/constants";
import { useSelector } from "react-redux";
// import LottieLoader from "../LottieLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  display: "flex",
  flexDirection: "column",
  borderRadius: "9px",
  color: "#7F7F81",
  // height: "41px",
  // position: "relative",
  // textTransform: "capitalize",
  // color: "#F0EBFF",
  // fontSize: "14px",
  padding: "7px 5px 7px 5px",
  // fontWeight: "500",
  // lineHeight: "21px",
  // flex: "0.65px !important",
  // display: "flex",
  // alignItems: "center",
  // width: "194px",
  // fontFamily: "Poppins, sans-serif",
  // "&:hover": {
  //   //  borderRadius: "9px",
  //   color: "#7F7F81",
  // },
}));
const ListItemIconStyle = styled(ListItemIcon)({
  width: 24,
  height: 24,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
function NavItem({ item, active }) {
  const { pathname } = useLocation();
  const { totalUnreadMessages } = useSelector((state) => state.conversations);
  const { t } = useTranslation();
  const { unreadNotificationsNumber, assignedNumber } = useSelector(
    (state) => state.notifications
  );
  const isActiveRoot = active === 1 ? true : false;
  const { title, path, icon, info, children } = item;
  const [openNavSection, setOpenNavSection] = useState(isActiveRoot);
  const handleOpenNavSection = () => {
    setOpenNavSection((prev) => !prev);
  };
  const activeRootStyle = {
    color: "#fec423",
    backgroundColor: "#fff8e5",

    "&:hover": {
      backgroundColor: "#fff8e5",
      color: "#fec423",
      transition: "all 0.5s ease-in-out !important",
    },
  };
  const activeSubStyle = {
    color: "text.primary",
  };
  const isDesktop = useResponsive("up", "lg");

  if (children && !isDesktop) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpenNavSection}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>
            {icon &&
              (["To Do List", "Notifications", "Inbox"].includes(title) ? (
                <Badge
                  badgeContent={
                    title === "Notifications"
                      ? unreadNotificationsNumber
                      : title === "To Do List"
                      ? assignedNumber
                      : totalUnreadMessages
                  }
                  color="primary"
                  className="custom-badge"
                >
                  <LazyLoadImage
                    style={{
                      filter:
                        ["To Do List", "Notifications"].includes(title) &&
                        !pathname.includes(path) &&
                        "invert(56%) sepia(0%) saturate(527%) hue-rotate(88deg) brightness(84%) contrast(86%)",
                    }}
                    src={icon}
                    alt={path}
                    className={
                      pathname.includes(path)
                        ? "active-sidebar-icon"
                        : "sidebar-icon"
                    }
                  />
                </Badge>
              ) : (
                <LazyLoadImage
                  src={icon}
                  alt={path}
                  style={{
                    filter:
                      title === "Archived" &&
                      !pathname.includes(path) &&
                      "invert(56%) sepia(0%) saturate(527%) hue-rotate(88deg) brightness(84%) contrast(86%)",
                  }}
                  className={
                    pathname.includes(path)
                      ? "active-sidebar-icon"
                      : "sidebar-icon"
                  }
                />
              ))}
          </ListItemIconStyle>
          <ListItemText
            disableTypography
            primary={title}
            className="nav-config-title"
            // sx={{ display: "flex !important" }}
          />
          {info && info}
        </ListItemStyle>
        <Collapse in={openNavSection} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, icon } = item;
              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  icon={icon}
                  active={pathname.includes(item.path) ? 1 : 0}
                  sx={{
                    ...(pathname.includes(item.path) && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(pathname.includes(item.path) && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText
                    disableTypography
                    primary={title}
                    className="nav-config-title"
                    // sx={{ display: "flex !important" }}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
        // ml: 1.25,
        // mr: 1.25,
        // display: "flex",
        // flexDirection: "column",
      }}
    >
      <ListItemIconStyle>
        {icon &&
          (["To Do List", "Notifications", "Inbox"].includes(title) ? (
            <Badge
              badgeContent={
                title === "Notifications"
                  ? unreadNotificationsNumber
                  : title === "To Do List"
                  ? assignedNumber
                  : totalUnreadMessages
              }
              color="primary"
              className="custom-badge"
            >
              <LazyLoadImage
                style={{
                  filter:
                    ["To Do List", "Notifications"].includes(title) &&
                    !pathname.includes(path) &&
                    "invert(56%) sepia(0%) saturate(527%) hue-rotate(88deg) brightness(84%) contrast(86%)",
                }}
                src={icon}
                alt={path}
                className={
                  pathname.includes(path)
                    ? "active-sidebar-icon"
                    : "sidebar-icon"
                }
              />
            </Badge>
          ) : (
            <LazyLoadImage
              src={icon}
              alt={path}
              style={{
                filter:
                  title === "Archived" &&
                  !pathname.includes(path) &&
                  "invert(56%) sepia(0%) saturate(527%) hue-rotate(88deg) brightness(84%) contrast(86%)",
              }}
              className={
                pathname.includes(path) ? "active-sidebar-icon" : "sidebar-icon"
              }
            />
          ))}
      </ListItemIconStyle>
      <ListItemText
        disableTypography
        primary={t(title)}
        className="nav-config-title"
      />
      {/* {path === "/messaging" && (
        <ListItemText
          disableTypography
          primary={
            totalUnreadMessages?.toString() ? (
              totalUnreadMessages?.toString()
            ) : (
              <LottieLoader />
            )
          }
          className="unread-messages-total"
          style={{
            backgroundColor: pathname.includes(path)
              ? "#c99fff"
              : "transparent",
          }}
        />
      )} */}
      {info && info}
    </ListItemStyle>
  );
}
function NavSection({ NAV_CONFIG, ...other }) {
  const { pathname } = useLocation();

  return (
    // <Box {...other} style={{ marginTop: "5px" }}>
    <List
      disablePadding
      sx={{
        marginTop: "48px",
        display: "flex",
        flexDirection: "column",
        gap: "13px",
      }}
    >
      <>
        {data.NAV_CONFIG.map((item) => {
          return (
            <NavItem
              key={item.id}
              item={item}
              active={pathname.includes(item.path) ? 1 : 0}
            />
          );
        })}
      </>
    </List>
    // </Box>
  );
}
export default NavSection;
